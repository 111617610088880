import { useCallback, useEffect, useState, useRef } from 'react';
import * as Sentry from '@sentry/browser';
import {
    getSecondsDifference,
    getWindowDimensions,
    getDeviceOrientation,
    setBodyOverflow,
    isMobile,
} from 'App/Helpers';

export const useConsentScreen = (adProvider, gameGA, ga) => {
    const [consent, setConsent] = useState(false);

    useEffect(() => {
        if (adProvider === 'adinplay') {
            document.addEventListener('aip_consentscreen', () => {
                // place gdpr modal lower
                const modal = document.querySelector('#cmpbox');

                if (modal) modal.style.zIndex = '999990';

                gameGA.sendDesignEvent('AIP:Consentscreen');
                ga.basicGaEvent('consent', 'aip_consentscreen');
                setConsent(true);
            });
            document.addEventListener('aip_consentscreenoff', () => {
                gameGA.sendDesignEvent('AIP:Consentscreenoff');
                ga.basicGaEvent('consent', 'aip_consentscreenoff');
                setConsent(false);
            });
            document.addEventListener('aip_consentapproved', () => {
                gameGA.sendDesignEvent('AIP:Consentapproved');
                ga.basicGaEvent('consent', 'aip_consentapproved');
            });
            document.addEventListener('aip_consentrejected', () => {
                gameGA.sendDesignEvent('AIP:Consentrejected');
                ga.basicGaEvent('consent', 'aip_consentrejected');
            });

            return () => {
                document.removeEventListener('aip_consentscreen');
                document.removeEventListener('aip_consentscreenoff');
                document.removeEventListener('aip_consentapproved');
                document.removeEventListener('aip_consentrejected');
            };
        }
    }, []);

    return consent;
};

/**
 * Hook computes initial game canvas dimensions based on the device type
 *
 * @param isMobile - boolean
 *
 * @return object - { canvasWidth: string, canvasHeight: string }
 */
export const useSetGameCanvasDimensions = (isMobile) => {
    const [canvasDimensions, setCanvasDimensions] = useState({
        canvasWidth: '',
        canvasHeight: '',
    });

    useEffect(() => {
        const { width, height } = getWindowDimensions();

        if (isMobile) {
            setBodyOverflow({ overflow: 'hidden' });
            setCanvasDimensions({
                canvasWidth: '100%',
                canvasHeight: `${height}px`,
            });
        } else {
            if (width >= height || width / height > 0.5625) {
                setCanvasDimensions({
                    canvasWidth: `${(height * 9) / 16}px`,
                    canvasHeight: '100%',
                });
            } else {
                setCanvasDimensions({
                    canvasWidth: '100%',
                    canvasHeight: `${(width * 16) / 9}px`,
                });
            }
        }
    }, []);

    return { ...canvasDimensions };
};

/**
 * Hook returns focus back to the game canvas on mouse down event

 */
export const useGameFocus = (canvasRef) => {
    useEffect(() => {
        canvasRef?.current?.addEventListener(
            'mousedown',
            handleMouseDown,
            false
        );

        return () => {
            canvasRef?.current?.removeEventListener(
                'mousedown',
                handleMouseDown
            );
        };
    }, []);

    const handleMouseDown = () => {
        window.focus();
    };
};

/**
 * Hook checks the game loading time and sends it along with analytics events to track loading performance
 *
 * @param isLoaded - boolean
 * @param gameGA - new () => Class (initialized Game Analytics Class instance)
 * @param ga - new () => Class (initialized Google Analytics Class instance)
 */
export const useLoadingTime = ({ isLoaded, gameGA, ga }) => {
    const [startLoadingTime, setStartLoadingTime] = useState(null);

    useEffect(() => {
        setStartLoadingTime(performance.now());
    }, []);

    useEffect(() => {
        if (isLoaded) {
            const loadingTime = getSecondsDifference(
                startLoadingTime,
                performance.now()
            );

            gameGA.sendDesignEvent('Game:LoadingTimeOnFront', loadingTime);
            ga.basicGaEvent('game', 'loading_time_on_front', loadingTime);
        }
    }, [isLoaded]);
};

/**
 * hook watches screen orientation changes
 *
 * @param gameGA - new () => Class (initialized Game Analytics Class instance)
 * @param ga - new () => Class (initialized Google Analytics Class instance)
 *
 * @return orientation | blockGameRender | orientationMessage | isMobileDevice
 */
export const useDeviceOrientation = (gameGA, ga) => {
    const isMobileDevice = isMobile();
    const [orientation, setOrientation] = useState(null);
    const [blockGameRender, setBlockGameRender] = useState(false);
    const [orientationMessage, setOrientationMessage] = useState(false);

    // check and set first screen orientation, block game render if "landscape"
    useEffect(() => {
        const firstRenderOrientation = getDeviceOrientation();

        if (isMobileDevice && firstRenderOrientation.startsWith('landscape')) {
            window.scrollTo(0, 0);
            setBlockGameRender(true);
        }

        setOrientation(firstRenderOrientation);
    }, []);

    // set screen orientation listener if device is mobile type
    useEffect(() => {
        if (isMobileDevice) {
            const handleOrientationChange = (event) => {
                const type = screen.orientation
                    ? event
                        ? event.target.type
                        : screen.orientation.type
                    : window.orientation === 0 || window.orientation === 180
                      ? 'portrait-primary'
                      : 'landscape-primary';

                setOrientation(type);
            };

            if (screen.orientation) {
                screen.orientation.addEventListener(
                    'change',
                    handleOrientationChange
                );

                return () =>
                    screen.orientation.removeEventListener(
                        'change',
                        handleOrientationChange
                    );
            } else {
                window.addEventListener(
                    'orientationchange',
                    handleOrientationChange
                );

                return () =>
                    window.removeEventListener(
                        'orientationchange',
                        handleOrientationChange
                    );
            }
        }
    }, []);

    // listens for screen orientation changes
    useEffect(() => {
        if (orientation) {
            gameGA.sendDesignEvent(
                'Game:ScreenOrientation',
                orientation.startsWith('portrait') ? 0 : 1
            );
            ga.basicGaEvent(
                'game',
                'screen_orientation',
                orientation.startsWith('portrait') ? 0 : 1
            );

            if (orientation.startsWith('landscape') && isMobileDevice) {
                gameGA.sendDesignEvent('Game:ScreenLandscapeWarning');
                ga.basicGaEvent('game', 'screen_landscape_warning');
            }
        }

        if (orientation && isMobileDevice) {
            if (orientation.startsWith('portrait')) {
                blockGameRender && setBlockGameRender(false);
                orientationMessage && setOrientationMessage(false);
            } else {
                const { height } = getWindowDimensions();
                let vh = height * 0.01;

                document.documentElement.style.setProperty('--vh', `${vh}px`);

                setOrientationMessage(true);
                gameGA.sendDesignEvent('Game:ScreenLandscapeWarning');
                ga.basicGaEvent('game', 'screen_landscape_warning');
            }
        }
    }, [orientation]);

    return { orientation, blockGameRender, orientationMessage, isMobileDevice };
};

/**
 * Hook sends different type of events to Game and Google Analytics from Unity Context
 *
 * @param gameGA - new () => Class (initialized GameAnalytics Class instance)
 * @param ga - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useSendAnalytics = ({
    gameGA,
    ga,
    addEventListener,
    removeEventListener,
}) => {
    const handleSendDesignEvent = useCallback(
        (eventName, value = undefined, fieldsJson = undefined) => {
            gameGA.sendDesignEvent(eventName, value, fieldsJson);
            ga.basicGaEvent('game', eventName, value);
        },
        []
    );

    const handleSendResourcesEvent = useCallback(
        (flowType, currency, amount, itemType, itemId) => {
            gameGA.sendResourceEvent(
                flowType,
                currency,
                amount,
                itemType,
                itemId
            );
        },
        []
    );

    const handleSendProgressionEvent = useCallback(
        (status, progression1, progression2, progression3, score) => {
            gameGA.sendProgressionEvent(
                status,
                progression1,
                progression2,
                progression3,
                score
            );
        },
        [gameGA]
    );

    const handleSendEventError = useCallback((errorType, message) => {
        gameGA.sendErrorEvent(errorType, message);
        ga.basicGaEvent('error', message);
    }, []);

    useEffect(() => {
        addEventListener('SendAnalyticsEvent', handleSendDesignEvent);
        addEventListener('SendProgressionEvent', handleSendProgressionEvent);
        addEventListener('SendResourcesEvent', handleSendResourcesEvent);
        addEventListener('SendErrorEvent', handleSendEventError);

        return () => {
            removeEventListener('SendAnalyticsEvent', handleSendDesignEvent);
            addEventListener('SendResourcesEvent', handleSendResourcesEvent);
            removeEventListener('SendErrorEvent', handleSendEventError);
            removeEventListener(
                'SendProgressionEvent',
                handleSendProgressionEvent
            );
        };
    }, [
        addEventListener,
        removeEventListener,
        handleSendDesignEvent,
        handleSendResourcesEvent,
        handleSendProgressionEvent,
        handleSendEventError,
    ]);
};

/**
 * Hook starts or ends Game Analytics session
 *
 * @param gameGA - new () => Class (initialized GameAnalytics Class instance)
 * @param ga - new () => Class (initialized GoogleAnalytics Class instance)
 * @param addEventListener - () => void (add event listener functions from the Unity Context)
 * @param removeEventListener - () => void (remove event listener functions from the Unity Context)
 */
export const useGameSession = ({
    gameGA,
    ga,
    addEventListener,
    removeEventListener,
}) => {
    const handleGameEndSession = useCallback(() => {
        gameGA.endGameSession();
        ga.basicGaEvent('game', 'end_ga_analytics_session');
    }, []);

    const handleGameStartSession = useCallback(() => {
        gameGA.startGameSession();
        ga.basicGaEvent('game', 'start_ga_analytics_session');
    }, []);

    useEffect(() => {
        addEventListener('UserStartIdle', handleGameEndSession);

        return () => removeEventListener('UserStartIdle', handleGameEndSession);
    }, [addEventListener, removeEventListener, handleGameEndSession]);

    useEffect(() => {
        addEventListener('UserEndIdle', handleGameStartSession);

        return () => removeEventListener('UserEndIdle', handleGameStartSession);
    }, [addEventListener, removeEventListener, handleGameStartSession]);
};

/**
 * hook loads and handles terms of use and privacy policy modal content
 */
export const useTermsAndPrivacyContent = ({ modalUrl, fetchLocal = false }) => {
    const [modalContent, setModalContent] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showTermsUse = async (element, type) => {
        element.preventDefault();
        !!modalContent && setModalContent(null);
        setIsModalOpen(true);

        try {
            if (fetchLocal) {
                // fetch local prebuild html modal content file
                const response = await fetch(element.target.href);

                if (response.status === 200) {
                    const data = await response.text();

                    if (data) {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(data, 'text/html');

                        setModalContent({
                            title: doc.title,
                            content: doc.body.innerHTML,
                        });
                        return;
                    }
                }
            }

            // call outside fallback modal content link if fetch local modal content file failed or fetch local files setting is off
            const fallbackResponse = await fetch(`${modalUrl}${type}`);
            const fallbackData = await fallbackResponse.json();

            if (fallbackData.length) {
                setModalContent({
                    title: fallbackData[0].title.rendered,
                    content: fallbackData[0].content.rendered,
                });
            } else {
                setModalContent({
                    title: 'Something went wrong',
                    content:
                        'No data available at the moment. Please try again later.',
                });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    const hideTermsUse = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    return [modalContent, isModalOpen, showTermsUse, hideTermsUse];
};

/**
 * hook sets a window resize event listener and displays ad side banners based on available window space
 *
 * @param sideContainerRef -  MutableRefObject<null>
 * @param gameGA - new () => Class (initialized GameAnalytics Class instance)
 * @param ga - new () => Class (initialized GoogleAnalytics Class instance)
 *
 * @return [sideNarrowBannerAd: boolean, sideWideBannerAd: boolean]
 */
export const useResizeListenerForBanners = (sideContainerRef, gameGA, ga) => {
    const [sideNarrowBannerAd, setNarrowSideBannerAd] = useState(false);
    const [sideWideBannerAd, setWideSideBannerAd] = useState(false);

    useEffect(() => {
        let resizeCalled = false;

        const setIsResized = () => {
            if (!resizeCalled) {
                gameGA.sendDesignEvent('Game:ResizeTriggered');
                ga.basicGaEvent('game', 'resize_triggered');
                resizeCalled = true;
            }
        };

        const sideAdsShowUp = () => {
            const { clientWidth } = sideContainerRef.current;

            if (clientWidth < 190 || window.innerHeight < 620) {
                setNarrowSideBannerAd(false);
                setWideSideBannerAd(false);
            } else if (clientWidth < 370 && window.innerHeight >= 620) {
                setNarrowSideBannerAd(true);
                setWideSideBannerAd(false);
            } else {
                setWideSideBannerAd(true);
                setNarrowSideBannerAd(false);
            }
        };

        sideAdsShowUp();

        const resizeHandler = () => {
            sideAdsShowUp();
            setIsResized();
        };

        window.addEventListener('resize', resizeHandler);

        return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    return [sideNarrowBannerAd, sideWideBannerAd];
};

/**
 * hook listens window resize event and return window dimensions
 *
 * @return width: number | height: number
 */
export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

export const useCopyrightDate = () => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        setCurrentYear(new Date().getFullYear());
    }, []);

    return currentYear;
};

export const useOutsideClick = (handler, attached = true) => {
    const outsideRef = useRef(null);

    useEffect(() => {
        if (!attached) return;

        const handleClickOutside = (e) => {
            if (outsideRef.current && !outsideRef.current.contains(e.target)) {
                handler();
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [handler, attached]);

    return outsideRef;
};

export const usePageLoad = () => {
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        const handleLoad = () => {
            setIsPageLoaded(true);
        };

        if (document.readyState === 'complete') {
            setIsPageLoaded(true); // If the document is already loaded
        } else {
            window.addEventListener('load', handleLoad); // Otherwise, wait for the load event
        }

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    return isPageLoaded;
};
